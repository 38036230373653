import React from "react"
import { graphql } from "gatsby"

import Modules from "../components/modules"
import Layout from "../components/layout"
import '@fitplan/web-components/dist/esm/index.css';
import "../assets/css/_global.css"
import "../assets/css/markDown.css"
import "../config.css"
import "../index.css"

const Page = props => {
  const faviconTitle = props?.data?.site?.siteMetadata?.title

  const {
    data: { header, content, cookieConsent, siteWideMeta, taggedPageListCollection, },
  } = props

  const siteData = siteWideMeta?.edges?.[0]?.node
  
  return (
    <Layout header={header} content={content} cookieConsent={cookieConsent}
      faviconTitle={faviconTitle}
      siteWideMeta={siteData}
      taggedPageListCollection={taggedPageListCollection?.edges}
    />
  )
}

export default Page

// eslint can't handle variables used in child fragments...
/* eslint-disable */
export const pageQuery = graphql`
  query ($id: String! $navbarId: String!) {
    site {
      siteMetadata {
        title
      }
    }
    siteWideMeta: allContentfulSite(
      limit: 1000
      filter: {
        node_locale: { eq: "en-US" }
        title: { ne: "Sample - DO NOT USE OR DELETE" }
      }
    ) {
      edges {
        node {
          id
          __typename
          metaAuthor
          metaThemeColor
          metaCreator
          metaSiteName
          metaGoogleBot
          metaMsnBot
          metaMsTileColor
          facebookAppId
          facebookDomainVerification
          metaMobileWebAppTitle
          metaApplicationName
          node_locale
          showCookieConsent
          gaMeasurementId
          gtmId
        }
      }
    }
    header: contentfulNavigator(id: { eq: $navbarId }) {
      ...Header
    }
    content: contentfulPage(id: { eq: $id }) {
      ...Page
    }
    cookieConsent: contentfulCookieConsent {
      ...CookieConsent
    }
    taggedPageListCollection: allContentfulPage(
      filter: { tags: { ne: null } }
    ) {
      edges {
        node {
          ...Page
        }
      }
    }
  }
`
